






















import { Component, Vue } from 'vue-property-decorator';

import WrapperInstability from '@/components/WrapperInstability/WrapperInstability.vue';

import MaintenancePage from '@/assets/imgs/maintenance/MaintenancePage.vue';

import instability from '@/mixins/Instability';

const DESCRIPTION_B2C = 'Para melhor desempenho e experiência, no momento estamos realizando manutenção em nossos serviços. Retornaremos em breve, enquanto isso, aproveite para acompanhar nosso conteúdo no canal do Explicaê no Youtube. ';
const DESCRIPTION_B2B = 'Para melhor desempenho e experiência, no momento estamos realizando manutenção em nossos serviços. Retornaremos em breve.';

@Component({
  mixins: [instability],
  components: {
    WrapperInstability,
    MaintenancePage,
  },
})
export default class Maintenance extends Vue {
  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get description() {
    return this.isB2b ? DESCRIPTION_B2B : DESCRIPTION_B2C;
  }
}
